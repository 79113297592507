

























/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from '@vue/composition-api'

interface IHash {
    [routePath: string] : string;
}

export default defineComponent({
  name: 'PagesCoreView',

  data: () => ({
    srcs: {
      '/pages/lock': 'lock.jpg',
      '/pages/login': 'login.jpg',
    } as IHash,
  }),

  computed: {
    src (): string {
      return this.srcs[this.$route.path]
    },
    styles (): any {
      const paddingTop = this.$vuetify.breakpoint.mdAndUp ? 175 : 100
      const paddingBottom = this.$vuetify.breakpoint.mdAndUp ? 175 : 150
      return {
        padding: `${paddingTop}px 0 ${paddingBottom}px 0`,
      }
    },
  },
})
